<template>
  <div class="mt-4 mx-auto" style="width: 90%">
    <!-- <div v-if="currentWorkspace?.connected"> -->
    <v-row class="mx-6">
      <v-col cols="5"></v-col>
      <v-col cols="4" class="">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Select Date Range"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="fetchGeneralLedger()">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="selectedAccount"
          @change="fetchGeneralLedger"
          :items="accountNames"
          outlined
          dense
          hide-details
          placeholder="All Accounts"
          class=""
          clearable
        />
      </v-col>
      <!-- <button
        @click="downloadData"
        style="background-color: #5f37e0; color: #fbfafd"
      >
        Download
      </button>
      <select v-model="downloadFormat">
        <option value="excel">Excel</option>
        <option value="pdf">PDF</option>
      </select> -->
    </v-row>

    <div v-if="loader" style="text-align: center">Loading...</div>
    <!-- General Ledger Data -->
    <div v-if="!loader">
      <div v-for="(ledger, accountName) in filteredLedger" :key="accountName">
        <h2 class="ml-10">{{ accountName }}</h2>
        <v-data-table
          :headers="headers"
          :items="ledger"
          :loading="loader"
          fixed-header
          height="60vh"
          class="listing"
        >
          <template v-slot:[`item.date`]="{ item }">{{
            item.date | formatISODate
          }}</template>
          <template v-slot:[`item.createdDate`]="{ item }">{{
            item.createdDate | formatISODate
          }}</template>
          <template v-slot:[`item.description`]="{ item }">{{
            item.description
          }}</template>
          <template v-slot:[`item.relatedAccount`]="{ item }">{{
            item.relatedAccount
          }}</template>
          <template v-slot:[`item.accountClass`]="{ item }">{{
            item.accountClass
          }}</template>
          <template v-slot:[`item.debit`]="{ item }">
            <span class="text-end">{{
              item.debit == 0 ? 0 : item.debit.toLocaleString()
            }}</span>
          </template>
          <template v-slot:[`item.credit`]="{ item }">{{
            item.credit == 0 ? 0 : item.credit.toLocaleString()
          }}</template>
          <template v-slot:[`item.balance`]="{ item }">{{
            item.balance.toLocaleString()
          }}</template>
        </v-data-table>
      </div>
    </div>
    <!-- </div> -->
    <!-- <no-software-connected v-if="!currentWorkspace?.connected" /> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/Api.js";
import dateFilter from "../../../js/DateFilters";
// import NoSoftwareConnected from "../../common/connect-software/NoSoftwareConnected.vue";
export default {
  components: {
    // NoSoftwareConnected,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      loader: false,
      selectedAccount: "",
      downloadFormat: "excel",
      menu: false,
      headers: [
        { text: "Date", value: "date" },
        { text: "Created Date", value: "createdDate" },
        { text: "Number", value: "journalNumber" },
        { text: "Description", value: "description" },
        { text: "Related Account", value: "relatedAccount" },
        { text: "Class", value: "accountClass" },
        { text: "Debit", value: "debit" },
        { text: "Credit", value: "credit" },
        { text: "Balance", value: "balance" },
      ],
      generalLedger: [],
      dates: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    accountNames() {
      return [...new Set(this.generalLedger.map((item) => item.accountName))];
    },
    filteredLedger() {
      const ledgerData = this.selectedAccount
        ? this.generalLedger.filter(
            (item) => item.accountName === this.selectedAccount
          )
        : this.generalLedger;

      const groups = this.groupByAccount(ledgerData);

      return groups;
    },
  },
  mounted() {
    this.fetchGeneralLedger();
  },
  methods: {
    async fetchGeneralLedger() {
      try {
        let dates = await dateFilter.currentYearDate();
        this.loader = true;
        let startDate = this.dates[0] ? this.dates[0] : "";
        let endDate = this.dates[1] ? this.dates[1] : "";
        let url = `report/general-ledger-db/${this.currentWorkspace.workspaceId}?startDate=${startDate}&endDate=${endDate}`;

        const response = await ApiService.GetRequest(url);
        this.loader = false;
        this.menu = false;
        console.log(response.data.generalLedgerData, "GL");
        this.generalLedger = response.data.generalLedgerData;
      } catch (error) {
        this.loader = false;
        console.error("Error fetching general ledger:", error);
      }
    },
    groupByAccount(data) {
      const groups = {};
      data.forEach((item) => {
        if (!groups[item.accountName]) {
          groups[item.accountName] = [];
        }
        if (item.date !== "Totals") {
          groups[item.accountName].push(item);
        }
      });

      // Add the totals row to each group
      data.forEach((item) => {
        if (item.date === "Totals") {
          groups[item.accountName].push(item);
        }
      });
      return groups;
    },
    downloadData() {
      // Logic to download data in selected format
    },
  },
};

// if (startDate) {
//   url += `?startDate=${startDate}`;
//   if (endDate) {
//     url += `&endDate=${endDate}`;
//   }
// } else if (endDate) {
//   url += `?endDate=${endDate}`;
// }
</script>
